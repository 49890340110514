// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import constants from '@/constants';
import editorNotificationMapper from './editorNotificationMapper';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import React from 'react';
import NotificationFrame from '../notificationFrame/notificationFrame';
import { RichText } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const {
  connect,
  STORES: { STATE_ONLY },
} = util.hoc;

const validateMessage = (props, propName, componentName) => {
  const msg = 'Warning: Failed propType: ';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(props[propName]) && _.isUndefined(props.symbolName)) {
    return new Error(
      `${msg} if not supplied 'symbolName', '${propName}' prop is required in component: ${componentName}`,
    );
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(props[propName]) && !_.isString(props[propName])) {
    return new Error(
      `${msg}Invalid prop \`${propName}\` of type \`${typeof props[
        propName
      ]}\` supplied to \`${componentName}\`, expected \`string\`.`,
    );
  }
};

//TYPE WAS GENERATED, remove this line when reviewed
interface EditorNotificationProps {
  name?: string;
  type: AnyFixMe;
  title?: string;
  message?: AnyFixMe;
  symbolName?: string;
  linkAction?: {
    caption: string;
    onClick: FunctionFixMe;
  };
  close: FunctionFixMe;
  notifications: Array<AnyFixMe>;
  resetNotificationTimer: FunctionFixMe;
  clearNotificationTimer: FunctionFixMe;
  origin?: string;
}

class EditorNotification extends React.Component<EditorNotificationProps> {
  static displayName = 'editorNotification';

  static propTypes = {
    name: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    type: PropTypes.oneOf(_.values(constants.NOTIFICATIONS.TYPES)).isRequired,
    title: PropTypes.string,
    message: validateMessage,
    symbolName: PropTypes.string,
    linkAction: PropTypes.shape({
      caption: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    close: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    resetNotificationTimer: PropTypes.func.isRequired,
    clearNotificationTimer: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const index = _.findKey(this.props.notifications, ['id', this.props.id]);
    this.props.biEvent(coreBi.events.notifications.editor_notification_appear, {
      location: index,
      topic: this.props.notifications[index].message,
      origin: this.props.origin,
      type: this.props.type,
    });
  }

  translateIfNeeded = (message) => {
    if (
      _.isBoolean(this.props.shouldTranslate) &&
      !this.props.shouldTranslate
    ) {
      return message;
    }

    return translate(message);
  };

  close = () => {
    const index = _.findKey(this.props.notifications, ['id', this.props.id]);
    this.props.biEvent(coreBi.events.notifications.editor_notification_close, {
      time_since_load: _.now() - this.props.notifications[index].startTime,
      finish_method: 'X',
      topic: this.props.notifications[index].title,
    });
    _.invoke(this.props.notifications[index], 'onClose');
    this.props.close();
  };

  onLinkClicked = () => {
    this.props.linkAction.onClick();
    const index = _.findKey(this.props.notifications, ['id', this.props.id]);
    this.props.close();
    this.props.biEvent(coreBi.events.notifications.editor_notification_close, {
      time_since_load: _.now() - this.props.notifications[index].startTime,
      finish_method: 'action_clicked',
      action: this.props.linkAction.caption,
      topic: this.props.notifications[index].title,
    });
  };

  onMouseEnter = (notification) => {
    this.props.clearNotificationTimer(notification, this.props.notifications);
  };

  onMouseLeave = (notification) => {
    const notificationState = this.props.notifications;
    const index = _.findKey(notificationState, ['id', notification.id]);
    if (index) {
      this.props.resetNotificationTimer(notification, notificationState, index);
    }
  };

  render() {
    return (
      <NotificationFrame
        style={{
          maxWidth: '600px',
        }}
        type={this.props.type}
        close={this.close}
      >
        <div
          onMouseEnter={() => this.onMouseEnter(this.props)}
          onMouseLeave={() => this.onMouseLeave(this.props)}
          data-aid="editor-notification-content"
          className="editor-notification-content"
        >
          <RichText>
            <p data-aid="notification-message" className="notification-message">
              {this.props.symbolName ? (
                <span
                  data-aid="notification-icon"
                  key="notification-icon"
                  className="notification-icon"
                >
                  <symbols.symbol name={this.props.symbolName} />
                </span>
              ) : null}{' '}
              {this.translateIfNeeded(this.props.message)}{' '}
              {this.props.linkAction && this.props.linkAction.caption ? (
                <a
                  data-aid="notification-content-link"
                  onClick={this.onLinkClicked}
                  key="notification-link-action"
                  className="notification-link"
                >
                  {this.translateIfNeeded(this.props.linkAction.caption)}
                </a>
              ) : null}
            </p>
          </RichText>
        </div>
      </NotificationFrame>
    );
  }
}

const { mapStateToProps, mapDispatchToProps } = editorNotificationMapper;

export default connect(
  STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(EditorNotification);
